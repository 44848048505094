var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Right Aligned Bordered Media"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeRightAlignBorder) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Wrap media list using ")]), _c('code', [_vm._v(".media-bordered")]), _c('span', [_vm._v(" class for bordered Media Object.")])]), _c('div', {
    staticClass: "media-list media-bordered"
  }, [_c('b-media', {
    staticClass: "text-right",
    attrs: {
      "right-align": ""
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-img', {
          attrs: {
            "src": require('@/assets/images/portrait/small/avatar-s-9.jpg'),
            "blank-color": "#ccc",
            "width": "64",
            "alt": "placeholder",
            "rounded": "circle"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('h4', {
    staticClass: "media-heading"
  }, [_vm._v(" Chupa chups candy ")]), _c('b-card-text', [_vm._v(" Cookie candy dragée marzipan gingerbread pie pudding. Brownie fruitcake wafer bonbon gummi bears apple pie. Brownie lemon drops chocolate cake donut croissant cotton candy. ")])], 1), _c('b-media', {
    staticClass: "text-right",
    attrs: {
      "right-align": ""
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-img', {
          attrs: {
            "src": require('@/assets/images/portrait/small/avatar-s-14.jpg'),
            "blank-color": "#ccc",
            "width": "64",
            "alt": "placeholder",
            "rounded": "circle"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('h4', {
    staticClass: "media-heading"
  }, [_vm._v(" Chocolate pudding ")]), _c('b-card-text', [_vm._v(" Jelly chocolate cake lemon drops halvah dragée caramels jelly-o biscuit. Fruitcake jelly beans marzipan sesame snaps.Jelly beans cake chocolate cake gummi bears lollipop. ")])], 1), _c('b-media', {
    staticClass: "text-right",
    attrs: {
      "right-align": ""
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-img', {
          attrs: {
            "src": require('@/assets/images/portrait/small/avatar-s-21.jpg'),
            "blank-color": "#ccc",
            "width": "64",
            "alt": "placeholder",
            "rounded": "circle"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('h4', {
    staticClass: "media-heading"
  }, [_vm._v(" Cotton candy jelly ")]), _c('b-card-text', [_vm._v(" Pudding marshmallow cheesecake. Chocolate cake apple pie jelly-o bear claw ice cream sugar plum biscuit. Lemon drops brownie biscuit jelly-o biscuit gummies. ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }