var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Vertical align"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeVerticalAlign) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Aside can be vertical aligned using ")]), _c('code', [_vm._v("vertical-align")]), _c('span', [_vm._v(" should be either ")]), _c('code', [_vm._v("top, center")]), _c('span', [_vm._v(" or ")]), _c('code', [_vm._v("end")]), _c('span', [_vm._v(" . Default is ")]), _c('code', [_vm._v("top")]), _vm._v(". ")]), _c('div', {
    staticClass: "media-list media-bordered"
  }, [_c('b-media', {
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-img', {
          attrs: {
            "src": require('@/assets/images/portrait/small/avatar-s-4.jpg'),
            "blank-color": "#ccc",
            "width": "64",
            "alt": "placeholder"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('h5', [_vm._v(" Top Aligned Media ")]), _c('b-card-text', [_vm._v(" Chocolate bar apple pie lollipop pastry candy canes. Candy soufflé brownie toffee tootsie roll. Brownie lemon drops chocolate cake donut croissant cotton candy ice cream. Chocolate pudding jelly beans powder carrot cake pastry. Sweet candy dragée cake brownie cheesecake jelly beans tart lemon drops. Liquorice cake fruitcake dragée sesame snaps candy canes biscuit pastry. Cookie caramels brownie carrot cake macaroon brownie pastry. Donut powder croissant tootsie roll cake cupcake. Cake tart icing jelly-o ice cream tootsie roll bear claw toffee brownie. Sugar plum danish muffin gummi bears. Bonbon chocolate bar candy canes sugar plum pie gingerbread wafer chupa chups gummi bears. Carrot cake oat cake jujubes cookie. Marzipan topping jelly brownie bear claw. Bonbon gummies fruitcake liquorice tart sesame snaps. ")])], 1), _c('b-media', {
    attrs: {
      "vertical-align": "center"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-img', {
          attrs: {
            "src": require('@/assets/images/portrait/small/avatar-s-1.jpg'),
            "blank-color": "#ccc",
            "width": "64",
            "alt": "placeholder"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('h5', {
    staticClass: "mt-1"
  }, [_vm._v(" Middle Aligned Media ")]), _c('b-card-text', [_vm._v(" Chocolate bar apple pie lollipop pastry candy canes. Candy soufflé brownie toffee tootsie roll. Brownie lemon drops chocolate cake donut croissant cotton candy ice cream. Chocolate pudding jelly beans powder carrot cake pastry. Sweet candy dragée cake brownie cheesecake jelly beans tart lemon drops. Liquorice cake fruitcake dragée sesame snaps candy canes biscuit pastry. Cookie caramels brownie carrot cake macaroon brownie pastry. Donut powder croissant tootsie roll cake cupcake. Cake tart icing jelly-o ice cream tootsie roll bear claw toffee brownie. Sugar plum danish muffin gummi bears. Bonbon chocolate bar candy canes sugar plum pie gingerbread wafer chupa chups gummi bears. Carrot cake oat cake jujubes cookie. Marzipan topping jelly brownie bear claw. Bonbon gummies fruitcake liquorice tart sesame snaps. ")])], 1), _c('b-media', {
    attrs: {
      "vertical-align": "bottom"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-img', {
          attrs: {
            "src": require('@/assets/images/portrait/small/avatar-s-16.jpg'),
            "blank-color": "#ccc",
            "width": "64",
            "alt": "placeholder"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('h5', {
    staticClass: "mt-1"
  }, [_vm._v(" Bottom Aligned Media ")]), _c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Chocolate bar apple pie lollipop pastry candy canes. Candy soufflé brownie toffee tootsie roll. Brownie lemon drops chocolate cake donut croissant cotton candy ice cream. Chocolate pudding jelly beans powder carrot cake pastry. Sweet candy dragée cake brownie cheesecake jelly beans tart lemon drops. Liquorice cake fruitcake dragée sesame snaps candy canes biscuit pastry. Cookie caramels brownie carrot cake macaroon brownie pastry. Donut powder croissant tootsie roll cake cupcake. Cake tart icing jelly-o ice cream tootsie roll bear claw toffee brownie. Sugar plum danish muffin gummi bears. Bonbon chocolate bar candy canes sugar plum pie gingerbread wafer chupa chups gummi bears. Carrot cake oat cake jujubes cookie. Marzipan topping jelly brownie bear claw. Bonbon gummies fruitcake liquorice tart sesame snaps. ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }