var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('media-left-align')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('media-right-align')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('media-left-align-border')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('media-right-align-border')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('media-nested')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('media-vertical-align')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('media-no-body')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }