var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Nested Media"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeNested) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-media', {
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-img', {
          attrs: {
            "src": require('@/assets/images/portrait/small/avatar-s-12.jpg'),
            "blank-color": "#ccc",
            "width": "64",
            "alt": "placeholder"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('h5', {
    staticClass: "mt-0"
  }, [_vm._v(" Media heading ")]), _c('b-card-text', [_vm._v(" Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. ")]), _c('b-media', {
    staticClass: "mt-3",
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-img', {
          attrs: {
            "src": require('@/assets/images/portrait/small/avatar-s-13.jpg'),
            "blank-color": "#ccc",
            "width": "64",
            "alt": "placeholder"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('h5', {
    staticClass: "mt-0"
  }, [_vm._v(" Media heading ")]), _c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }